exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-case-studies-appety-tsx": () => import("./../../../src/pages/case-studies/appety.tsx" /* webpackChunkName: "component---src-pages-case-studies-appety-tsx" */),
  "component---src-pages-case-studies-timetrack-tsx": () => import("./../../../src/pages/case-studies/timetrack.tsx" /* webpackChunkName: "component---src-pages-case-studies-timetrack-tsx" */),
  "component---src-pages-case-studies-trampil-tsx": () => import("./../../../src/pages/case-studies/trampil.tsx" /* webpackChunkName: "component---src-pages-case-studies-trampil-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */)
}

